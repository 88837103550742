import { useField, useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ru from 'react-phone-number-input/locale/ru.json'
import en from 'react-phone-number-input/locale/en.json'
import pt from 'react-phone-number-input/locale/pt.json'
import { Modal } from 'react-bootstrap'
import { useSetupContext } from '../helpers/SetupContext'
import { useAuth } from '../../modules/auth'
import { ComponentPhoneType } from '../../types/components'
import setModalIndex from '../helpers/setModalIndex'
import api from '../../api'
import ComponentButton from './ComponentButton'


const ComponentPhoneV2 = React.memo<ComponentPhoneType>(props => {
    const context = useSetupContext()
    const { currentUser } = useAuth()

    const resolvedSettings = useMemo(() => {
        switch (context.phone_format) {
            case "ru":
                return { labels: ru, defaultCountry: "RU" }
            case "usa":
                return { labels: en, defaultCountry: "US" }
            case "lat":
                return { labels: en, defaultCountry: "LV" }
            case "pt":
                return { labels: pt, defaultCountry: "PT" }
            case "eng":
            default:
                return { labels: pt, defaultCountry: "PT" }
        }
    }, [context])

    const { article, is_disabled, customHandler } = props
    const [field, meta] = useField(article)
    const { value, onBlur } = field
    const { setFieldValue } = useFormikContext()
    const isError = Boolean(meta.error && meta.touched)


    const resolvedValue = useMemo(() => {
        if (typeof value === "string") {
            return value.startsWith("+") ? value : "+" + value
        } else {
            return
        }
    }, [value])

    const showCallButton = Boolean(context.dom_ru && currentUser?.domru_login)
    const isPossiblePhone = isPossiblePhoneNumber(resolvedValue ?? "")


    const [initializeCall, setInitializeCall] = useState(false)

    useEffect(() => {
        if (initializeCall) {
            const id = setTimeout(() => setInitializeCall(false), 3000)
            return () => clearTimeout(id)
        }
    }, [initializeCall])

    const handleMakeCall = () => {
        if (isPossiblePhone && currentUser?.domru_login) {
            api("dom_ru", "make_call", { user: currentUser.domru_login, phone: resolvedValue?.replace(/[^\d]/g, "") })
            setInitializeCall(true)
        }
    }

    const handleChange = (value: any) => {
        const resolvedValue = (value === undefined) ? null : value

        setFieldValue(article, resolvedValue)

        if (customHandler) {
            customHandler(resolvedValue)
        }

    }

    return <>
        <div className="componentPhoneV2_container">
            <Modal show={initializeCall} onHide={() => setInitializeCall(false)} size="sm" centered onEntering={setModalIndex}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Внимание
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Инициализация звонка</p>
                </Modal.Body>
            </Modal>
            <PhoneInput
                className={`componentPhoneV2 form-control form-control-solid ${isError ? " invalid" : ""} ${showCallButton ? " withButton" : ""}`}
                labels={resolvedSettings.labels}
                //@ts-ignore
                value={resolvedValue}
                onChange={handleChange}
                onBlur={onBlur}
                //@ts-ignore
                defaultCountry={resolvedSettings.defaultCountry}
                disabled={is_disabled}

            />
            {showCallButton ?
                <ComponentButton
                    className="componentPhoneV2_button"
                    type="custom"
                    settings={{ title: "Инициализировать звонок", icon: "phone", background: "dark" }}
                    defaultLabel="icon"
                    customHandler={handleMakeCall}
                    disabled={!isPossiblePhone}
                /> : null}
        </div>
        {isError ? <div className="invalid_feedback">
            {meta.error}
        </div> : null}
    </>
})

export default ComponentPhoneV2