/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import { useIntl } from 'react-intl'
import api from '../../../../app/api'
import { getErrorToast } from '../../../../app/constructor/helpers/toasts'

const languages = [
  {
    lang: 'en',
    name: 'English (GB)',
    flag: toAbsoluteUrl('/media/flags/united-kingdom.svg'),
  },
  {
    lang: 'us',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
  /* {
    lang: 'lv',
    name: 'Latviski',
    flag: toAbsoluteUrl('/media/flags/latvia.svg'),
  }, */
  {
    lang: 'ro',
    name: 'Română',
    flag: toAbsoluteUrl('/media/flags/romania.svg'),
  },
  {
    lang: 'pt',
    name: 'Português',
    flag: toAbsoluteUrl('/media/flags/portugal.svg'),
  },

]

const handleLanguageChange = async (lang: string) => {
  try {
    const {data: token} = await api("users", "change-lang", {lang})
    if (token) {
      localStorage.setItem("authToken", token)
      setLanguage(lang)
    } else {
      throw new Error("Что-то пошло не так")
    }
  } catch (er: any) {
    getErrorToast(er.message)
  }
}
const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const intl = useIntl()

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: "APPLICATION.LANGUAGE"})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              handleLanguageChange(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
